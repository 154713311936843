/**
 * React.
 */
import React from "react"

/**
 * Gatsby.
 */
import { graphql } from "gatsby"

/**
 * Layout.
 * Used for basic page structure.
 */
import Layout from "../components/layout"

/**
 * SEO.
 * Used for page SEO content.
 */
import Seo from "../components/seo"

/**
 * Featured Images.
 * Used for WordPress Post/Page/CustomPost featured images.
 */
import FeaturedImage from "../components/featuredImages"

/**
 * Author Bio.
 * Used for WordPress Post/Page/CustomPost author bio.
 */
import AuthorBio from "../components/authorBio"

const WpPost = ({ data }) => { 

  /* Get wpPost data variables */
  const {
    wpPost: { title, content, id, seo, featuredImage, author },
  } = data
  
  return (
    <Layout className={`post-${id}`}>

      {/* SEO details */}
      <Seo title={title} id={id} yoastseo={seo} />

      {/* Featured image */}
      <FeaturedImage image={featuredImage} />

      {/* Post title */}
      <h1>{title}</h1> 

      {/* Post content */}
      <div dangerouslySetInnerHTML={{ __html: content }} /> 

      {/* Author Bio */}
      <AuthorBio author={author.node} />

    </Layout>
  )
}

/* Get wpPost data */ 
export const query = graphql`
  query($id: String ) {
    wpPost(id: { eq: $id }) {
      id
      title
      content
      seo {
        fullHead
        title
        metaDesc
        canonical
        opengraphDescription
        opengraphTitle
        opengraphUrl
        opengraphSiteName
        opengraphModifiedTime
        opengraphImage {
          mediaItemUrl
          mediaDetails {
            height
            width
          }
          mimeType
        }
        twitterTitle
        twitterDescription
        twitterImage {
          mediaItemUrl
        }
        schema {
          articleType
          pageType
          raw
        }
      }               
      featuredImage {
        node {
          mediaDetails {
            file
          }
          altText
        }
      }
      author {
        node {
          ...AuthorBio
        }
      } 
    }
  }
`

export default WpPost