/**
 * React.
 */
import React from "react"

/**
 * Gatsby.
 */
import { graphql } from "gatsby"

const AuthorBio = ({ author: { id, name, description } }) => {

    /* Return author details */
    return (
      <div className={`author-${id}`}>
        <div>Author:</div>
        <h2 className='author-name'>{name}</h2>
        <div className='author-description' dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    )
  }

 /* Get author data */ 
export const AuthorFragment = graphql`
  fragment AuthorBio on WpUser {
    id
    name
    description
  }
`

export default AuthorBio